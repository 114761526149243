import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import settingsParams, {
  uninitializedVisibilitySettingsTrueValue,
} from '../settingsParams';
import { VisibilityKey } from './types';

export function useVisibilityCheck() {
  const settings = useSettings();
  const styles = useStyles();
  const { isCssPerBreakpoint } = useEnvironment();

  return {
    shouldBeVisible(key: VisibilityKey) {
      const settingsValue = settings.get(settingsParams[key]);
      if (isCssPerBreakpoint) {
        return typeof settingsValue === 'boolean'
          ? settingsValue
          : styles
              .getStylesForAllBreakpoints()
              .some(({ booleans }) => booleans[key]);
      } else {
        return typeof settingsValue === 'boolean'
          ? settingsValue
          : settingsValue === uninitializedVisibilitySettingsTrueValue;
      }
    },
  };
}
