import { OfferingCategoryDto } from '@wix/bookings-uou-domain';
import { CatalogServiceDto, ServiceLocation } from '@wix/bookings-uou-types';

export enum ViewMode {
  PAGE,
  WIDGET,
}

export enum AlignmentOptions {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum ServiceInfoAlignmentOptions {
  CENTER = 'center',
  TOP = 'top',
  BOTTOM = 'bottom',
}

export enum FilterLayoutOptions {
  TABS = 'tabs',
  TAGS = 'tags',
  DROPDOWN = 'dropdown',
}

export enum UnitOptions {
  PIXEL = 'px',
  PERCENT = '%',
}

export enum ImageResizeOptions {
  CROP = 'crop',
  FIT = 'fit',
}

export enum MigrationStatus {
  NOT_MIGRATED = 'NOT_MIGRATED',
  MIGRATED = 'MIGRATED',
  MIGRATED_WITH_SERVICES_WARNING = 'MIGRATED_WITH_SERVICES_WARNING',
  MIGRATED_WITH_FEATURE_SERVICE_WARNING = 'MIGRATED_WITH_FEATURE_SERVICE_WARNING',
  FAILED = 'FAILED',
}

export enum ImageShapeOptions {
  SQUARE = 'square',
  ROUND = 'round',
  RECTANGLE = 'rectangle',
}

export enum ImageSizeOptions {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum ImagePositionOptions {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum OfferingLayoutOptions {
  SIDE_BY_SIDE = 'SIDE_BY_SIDE',
  OVERLAPPING = 'OVERLAPPING',
}

export enum ImageAndTextRatioOptions {
  RATIO_50_50 = '50',
  RATIO_40_60 = '40',
  RATIO_30_70 = '30',
}

export enum ButtonStyleOptions {
  SQUARE_HOLE = 'SQUARE_HOLE',
  SQUARE_FILL = 'SQUARE_FILL',
  CIRCLE_HOLE = 'CIRCLE_HOLE',
  CIRCLE_FILL = 'CIRCLE_FILL',
}

export enum DockLocationOptions {
  MIDDLE = 'MIDDLE',
  RIGHT = 'RIGHT',
  LEFT = 'LEFT',
  BOTTOM = 'BOTTOM',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  TOP_RIGHT = 'TOP_RIGHT',
  TOP_LEFT = 'TOP_LEFT',
  TOP = 'TOP',
}

export enum ServiceListLayoutOptions {
  CLASSIC = 'CLASSIC',
  OVERLAPPING = 'OVERLAPPING',
  STRIP = 'STRIP',
  GRID = 'GRID',
  MOBILE = 'MOBILE',
}

export enum FilterServicesByOptions {
  CATEGORIES = 'SERVICE_CATEGORIES',
  LOCATIONS = 'SERVICE_LOCATIONS',
}

export enum DisplayServicesByOptions {
  BY_SERVICES = 'BY_SERVICES',
  BY_SPECIFIC_SERVICES = 'BY_SPECIFIC_SERVICES',
  BY_LOCATIONS = 'BY_LOCATIONS',
}

export enum ServiceClickTarget {
  BOOKING_CALENDAR = 'BOOKING_CALENDAR',
  SERVICE_PAGE = 'SERVICE_PAGE',
}

export enum SettingsTab {
  Manage = 'service_list_page/manage',
  Services = 'service_list_page/services',
  Display = 'service_list_page/display',
  Layout = 'service_list_page/layout',
  Design = 'service_list_page/design',
  Text = 'service_list_page/text',
}

export type FilterOption = {
  id: string;
  title: string;
  isSelected: boolean;
};

export type Point = {
  x: number;
  y: number;
};

export type Resources = {
  offerings: CatalogServiceDto[];
  categories: OfferingCategoryDto[];
  locations: ServiceLocation[];
};
